import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Layout, { ChildrenWrapper, JustifyContentType } from '@kvika/layout';
import Header from '@kvika/header';
import { ProgressStepper } from '@kvika/progress';
import Button from '@kvika/button';
import styled, { css } from 'styled-components';
import { OnboardingType } from '@kvika/api-types';
import { MediaQuery } from '@kvika/theme';
import { logout, selectSessionState } from '../../store/session';
import { resetEntityState, selectEntityState } from '../../store/entity';
import { getPathnameComponents, shouldDisplayClearAnswers } from '../../utils/Utils';
import LogoutButton from './buttons/LogoutButton';
import BannerWrapper from './BannerWrapper';
import { clearSession } from '../../utils/AuthenticationStorage';
import LayoutImage from './LayoutImage';
import { getOnboardingSteps, getRouterPath, getStepForPage } from '../../utils/Navigation';
import { NavigationConstants } from '../../constants/NavigationConstants';
import { clearAnswers, selectCurrentOnboardingFlow } from '../../store/answer';
import { clearErrorState, updateError } from '../../store/error';
import { getKvikaApiClient, parseApiError } from '../../utils/ApiUtils';
import { PersonalInfo } from '../../constants/Text';
import LayoutTextHeader from './LayoutTextHeader';
import { ALLOWED_ROUTES, FLOW_PROTECTED_ROUTES } from '../../constants/RoutesConstants';
import { resetCompanyState } from '../../store/companies';

type Props = {
  children?: React.ReactNode | React.ReactNode[];
  hideTextHeader?: boolean;
  imageUrl?: string;
  imageAlt?: string;
  showClearAnswers?: boolean;
  justifyContent?: JustifyContentType;
  title?: string;
  description?: string | React.ReactNode;
  showBanner?: boolean;
  kardio?: boolean;
};

export const shouldRedirectToLogin = (
  isLoggedIn: boolean,
  routerPath: string,
  currentOnboardingFlow?: OnboardingType
) => {
  if (!currentOnboardingFlow) {
    return true;
  }

  if (ALLOWED_ROUTES.includes(routerPath)) {
    // if entire path is considered public, then the user should not be redirected to login
    return false;
  }

  const pathComponents = getPathnameComponents(routerPath);

  let storedPathComponent = '';
  for (const pathComponent of pathComponents) {
    const routeToCheck = `${storedPathComponent}/${pathComponent}`;

    const isRoutePublic = ALLOWED_ROUTES.includes(routeToCheck);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isRouteProtected = (FLOW_PROTECTED_ROUTES as any)[currentOnboardingFlow].includes(routeToCheck);

    if (!isRoutePublic) {
      // if any prefix of the path is considered public continue with checking subroutes
      if (isRouteProtected && !isLoggedIn) {
        // the user is not logged in and any prefix of path is protected, then the user should be redirected to login
        return true;
      }
    }

    storedPathComponent = routeToCheck;
  }

  return false;
};

export const LayoutWrapper = ({
  children,
  hideTextHeader = false,
  imageUrl = '/mynd_vindmyllur.png',
  imageAlt = 'Vindmyllur og landslag',
  showClearAnswers = true,
  justifyContent,
  title,
  description,
  showBanner = true,
  kardio = false,
}: Props) => {
  const [isClearAnswersLoading, setIsClearAnswersLoading] = React.useState(false);
  const router = useRouter();
  const entity = useSelector(selectEntityState);
  const currentOnboardingFlow = useSelector(selectCurrentOnboardingFlow);
  const { loggedIn, kardioLoggedIn } = useSelector(selectSessionState);
  const dispatch = useDispatch();
  const apiClient = getKvikaApiClient();
  const [authorized, setAuthorized] = React.useState(false);

  const isKardio = currentOnboardingFlow === OnboardingType.KARDIO;

  const onLogout = React.useCallback(() => {
    if (!loggedIn) {
      return;
    }

    let redirect = '/';
    if (kardioLoggedIn) {
      redirect = '/kardio';
    }
    dispatch(logout());
    clearSession();
    dispatch(clearAnswers());
    dispatch(resetCompanyState());
    dispatch(resetEntityState());
    dispatch(clearErrorState());

    router.push(redirect);
  }, [dispatch, router, loggedIn, kardioLoggedIn]);

  const onClearAnswers = React.useCallback(async () => {
    setIsClearAnswersLoading(true);
    // TODO: Add some error handling here
    try {
      if (entity.isCompany) {
        if (entity.ssn) {
          await apiClient.clearCompanyAnswersOnStaging(entity.ssn);
        }
      } else {
        await apiClient.clearAnswersOnStaging();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(updateError(parseApiError(error)));
    }

    setIsClearAnswersLoading(false);
    onLogout();
  }, [apiClient, dispatch, entity.isCompany, entity.ssn, onLogout]);

  const getCurrentStep = () => {
    const currentStep = getStepForPage(router.asPath.replace('/', ''), currentOnboardingFlow);
    const steps = getOnboardingSteps(currentOnboardingFlow) || [];
    return steps.findIndex((step) => {
      return step.label === currentStep;
    });
  };

  const currentStepIndex = getCurrentStep();
  const steps = getOnboardingSteps(currentOnboardingFlow);
  const layoutImage = kardio || isKardio ? '/KardioLoginImage.png' : imageUrl;

  React.useEffect(() => {
    // authorize access only if user is logged in and on page of current flow or if he is not logged in but on login pages
    const userShouldRedirectToLogin = shouldRedirectToLogin(loggedIn, router.pathname, currentOnboardingFlow);

    if (userShouldRedirectToLogin) {
      setAuthorized(false);
      onLogout();
    } else {
      setAuthorized(true);
    }
  }, [currentOnboardingFlow, loggedIn, router.pathname, onLogout]);

  if (!authorized) {
    return null;
  }

  return (
    <Layout image={<LayoutImage url={layoutImage} alt={imageAlt} />}>
      <StyledHeader $kardio={kardio || isKardio}>
        {(kardio || isKardio) && <KardioLogo src="/Kardio_Logo.png" alt="Kardio logo" height={32} width={120.22} />}
        {shouldDisplayClearAnswers(showClearAnswers) && !(kardio || kardioLoggedIn) && (
          <div>
            <Button loading={isClearAnswersLoading} onClick={onClearAnswers}>
              Hreinsa svör
            </Button>
          </div>
        )}
        {loggedIn && <LogoutButton text="Útskrá" onClick={onLogout} />}
      </StyledHeader>

      {currentStepIndex !== -1 && <StyledProgressStepper steps={steps} currentStepIndex={currentStepIndex} />}
      {showBanner && (
        <BannerWrapper
          banner={{
            logged_in_as: PersonalInfo.LoggedInAs,
            on_behalf: PersonalInfo.OnBehalf,
            switch: PersonalInfo.Switch,
          }}
          onClick={() => {
            router.push(getRouterPath(NavigationConstants.FYRIRTAEKI));
          }}
        />
      )}

      <ChildrenWrapper justifyContent={justifyContent}>
        {!hideTextHeader && <LayoutTextHeader title={title} description={description} />}
        {children}
      </ChildrenWrapper>
    </Layout>
  );
};

export default LayoutWrapper;

const StyledHeader = styled(Header)<{ $kardio: boolean }>`
  ${(props) =>
    props.$kardio &&
    css`
      min-height: 112px;
      justify-content: start;
      align-items: center;
      gap: 32px;
      height: max-content;
      padding-top: 32px;
      padding-bottom: 32px;
      @media (max-width: ${MediaQuery.Mobile}) {
        gap: 16px;
        padding: 0px 16px;
      }
      & > div:nth-child(1) {
        max-width: 133px;
        max-height: 48px;
        height: auto !important;
        width: auto !important;
        margin: 0;
        min-width: 60px;
        svg {
          max-height: 48px;
          width: 100%;
          height: 100%;
          display: grid;
          place-content: center;
        }
      }
      & > div:nth-child(2) {
        flex: 1 1 auto;
        min-width: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
        @media (max-width: ${MediaQuery.Mobile}) {
          gap: 16px;
        }
      }
    `}
`;

const KardioLogo = styled(Image)`
  width: auto !important;
  height: auto !important;
  min-width: 60px;
`;

const StyledProgressStepper = styled(ProgressStepper)`
  @media (max-width: ${MediaQuery.Mobile}) {
    margin: 0px 16px;
    padding: 0px;
  }
`;
