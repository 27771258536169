import { OnboardingCompanyInfoSchema, OnboardingType } from '@kvika/api-types';
import ApiClient, { ApiError } from '@kvika/api-client';
import { AxiosResponseHeaders } from 'axios';
import { OnboardingStatusState } from './Utils';
import { getAuthenticationToken, setAuthenticationToken } from './AuthenticationStorage';
import { ErrorData } from '../types/Types';

export const getKvikaApiClient = (
  onResponseHeaders?: (headers: AxiosResponseHeaders) => void,
  loginRequestToken?: string
): ApiClient => {
  return new ApiClient({
    apiUrl: process.env.NEXT_PUBLIC_BASE_URL ?? '',
    appToken: process.env.NEXT_PUBLIC_X_AUTH_TOKEN ?? '',
    authToken: getAuthenticationToken() ?? '',
    storeAuthToken: setAuthenticationToken,
    onResponseHeaders,
    loginRequestToken,
  });
};

// This is needed due to redux complaining when we pass the raw error which contains functions and other
// non-serializable objects
// https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants

export const parseApiError = (error: ApiError): ErrorData => {
  const headers = error.response?.headers.toObject
    ? error.response?.headers.toObject()
    : { ...error.response?.headers };
  return {
    data: error.response?.data,
    status: error.response?.status,
    headers,
  };
};

export const getGeneralBankingOnboardingStatus = async (ssn: string) => {
  try {
    const apiClient = getKvikaApiClient();
    const response = await apiClient.getGeneralBankingOnboarding(ssn);
    const isPepBlocked = response.isPEP.isBlocked;
    const {
      isComplete,
      isSubmitted,
      isRiskBlocked,
      isConfirmationBlocked,
      isPinSet,
      companyInfo,
      isBeneficialOwnersBlocked,
    } = response;

    return {
      isComplete,
      isConfirmationBlocked,
      isPepBlocked,
      isRiskBlocked,
      isSubmitted,
      isPinSet,
      companyInfo,
      isBeneficialOwnersBlocked,
    };
    // TODO: Have proper typing here by refactoring this to use catch instead of then
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      isComplete: false,
      isConfirmationBlocked: false,
      isPepBlocked: false,
      isRiskBlocked: false,
      isSubmitted: false,
      isPinSet: false,
      isBeneficialOwnersBlocked: false,
      isNotFound: e.response && e.response.status === 404,
    };
  }
};

export const getKardioOnboardingStatus = async (ssn: string) => {
  try {
    const apiClient = getKvikaApiClient();
    const response = await apiClient.getKardioOnboarding(ssn);
    const isPepBlocked = response.isPEP.isBlocked;
    const {
      isComplete,
      isSubmitted,
      isRiskBlocked,
      isConfirmationBlocked,
      isPinSet,
      companyInfo,
      isBeneficialOwnersBlocked,
    } = response;

    return {
      isComplete,
      isConfirmationBlocked,
      isPepBlocked,
      isRiskBlocked,
      isSubmitted,
      isPinSet,
      companyInfo,
      isBeneficialOwnersBlocked,
    };
    // TODO: Have proper typing here by refactoring this to use catch instead of then
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      isComplete: false,
      isConfirmationBlocked: false,
      isPepBlocked: false,
      isRiskBlocked: false,
      isSubmitted: false,
      isPinSet: false,
      isBeneficialOwnersBlocked: false,
      isNotFound: e.response && e.response.status === 404,
    };
  }
};

export const getCapitalMarketsOnboardingStatus = async (ssn: string) => {
  try {
    const apiClient = getKvikaApiClient();
    const response = await apiClient.getCapitalMarketsOnboarding(ssn);

    const isPepBlocked = response.isPEP.isBlocked;
    const {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isRiskBlocked,
      isConfirmationBlocked,
      companyInfo,
      isBeneficialOwnersBlocked,
      hasLEICode,
    } = response;
    return {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isPepBlocked,
      isRiskBlocked,
      isConfirmationBlocked,
      companyInfo,
      isBeneficialOwnersBlocked,
      hasLEICode,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      isComplete: false,
      isSubmitted: false,
      actingRetail: false,
      isPepBlocked: false,
      isRiskBlocked: false,
      isConfirmationBlocked: false,
      isBeneficialOwnersBlocked: false,
      isNotFound: e.response && e.response.status === 404,
    };
  }
};

export const getPrivateBankingOnboardingStatus = async (ssn: string) => {
  try {
    const apiClient = getKvikaApiClient();
    const response = await apiClient.getPrivateBankingOnboarding(ssn);

    const isPepBlocked = response.isPEP.isBlocked;
    const {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isRiskBlocked,
      isConfirmationBlocked,
      companyInfo,
      isBeneficialOwnersBlocked,
    } = response;
    return {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isPepBlocked,
      isRiskBlocked,
      isConfirmationBlocked,
      companyInfo,
      isBeneficialOwnersBlocked,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      isComplete: false,
      isSubmitted: false,
      actingRetail: false,
      isPepBlocked: false,
      isRiskBlocked: false,
      isConfirmationBlocked: false,
      isBeneficialOwnersBlocked: false,
      isNotFound: e.response && e.response.status === 404,
    };
  }
};

export const getDerivativesOnboardingStatus = async (ssn: string) => {
  try {
    const apiClient = getKvikaApiClient();
    const response = await apiClient.getDerivativesOnboarding(ssn);

    const isPepBlocked = response.isPEP.isBlocked;
    const {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isRiskBlocked,
      isConfirmationBlocked,
      companyInfo,
      isBeneficialOwnersBlocked,
      isApprovalBlocked,
    } = response;
    return {
      isComplete,
      investorType,
      isSubmitted,
      actingRetail,
      isPepBlocked,
      isRiskBlocked,
      isConfirmationBlocked,
      companyInfo,
      isBeneficialOwnersBlocked,
      isApprovalBlocked,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      isComplete: false,
      isSubmitted: false,
      actingRetail: false,
      isPepBlocked: false,
      isRiskBlocked: false,
      isConfirmationBlocked: false,
      isBeneficialOwnersBlocked: false,
      isNotFound: e.response && e.response.status === 404,
    };
  }
};

export const getOnboardingStatus = async (
  ssn: string
): Promise<{ data: OnboardingStatusState; companyInfo: OnboardingCompanyInfoSchema | undefined }> => {
  const generalBanking = await getGeneralBankingOnboardingStatus(ssn);
  const capitalMarkets = await getCapitalMarketsOnboardingStatus(ssn);
  const privateBanking = await getPrivateBankingOnboardingStatus(ssn);
  const derivatives = await getDerivativesOnboardingStatus(ssn);
  const kardio = await getKardioOnboardingStatus(ssn);
  return {
    data: {
      [OnboardingType.GENERAL_BANKING]: generalBanking,
      [OnboardingType.CAPITAL_MARKETS]: capitalMarkets,
      [OnboardingType.PRIVATE_BANKING]: privateBanking,
      [OnboardingType.DERIVATIVES]: derivatives,
      [OnboardingType.KARDIO]: kardio,
      [OnboardingType.AUDUR]: {} as Record<string, unknown>,
    },
    companyInfo: capitalMarkets.companyInfo || generalBanking.companyInfo || kardio.companyInfo || undefined,
  };
};
